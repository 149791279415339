import Vue from "vue";
import Vuex from 'vuex'

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        keyword: '', //公司名称关键字搜索
        user: { //登录的用户的信息
            id: '',
            accumulateDays: '',
            roleLevel: '',
            username: '',
        },
    },

    mutations: {
        changeKeyword(state, newValue) {
            state.keyword = newValue;
        },

        setUser(state, newValue) {
            Object.assign(state.user, newValue);
        },
    },

    getters: {
        user: state => {
            return state.user;
        },
        keyword: state => {
            return state.keyword;
        }
    }
})

export default store;