<template>
  <div class="login-container">

    <!--背景图-->
    <div class="login-backPic">
      <img src="@/assets/imgs/dog_walking.svg">
    </div>

    <!--登录框-->
    <div class="login-box">

      <div class="login-box-div">
        <el-form ref="login" :model="login" class="login-form" :rules="rules">
          <el-form-item prop="username">
            <el-input class="form-input" v-model="login.username" placeholder="请输入用户名"
                      @keyup.enter.native="confirmLogin"
                      prefix-icon="el-icon-s-custom"></el-input>
          </el-form-item>

          <el-form-item prop="password">
            <el-input class="form-input" v-model="login.password" placeholder="请输入密码" show-password
                      @keyup.enter.native="confirmLogin"
                      prefix-icon="el-icon-lock"></el-input>
          </el-form-item>


          <el-form-item class="btns">
            <el-button class="light-btn" @click="openRegister">注册</el-button>
            <el-button class="dark-btn" @click="confirmLogin" v-loading.fullscreen.lock="loginLoading">登录</el-button>
          </el-form-item>
        </el-form>
      </div>

    </div>


    <!--备案号-->
    <a class="beian" target="_blank" href="https://beian.miit.gov.cn/">蜀ICP备2022016591号-1</a>


    <!--注册模态框-->
    <el-dialog custom-class="register-dialog"
               title="注册"
               :visible.sync="registerVisible"
               width="30%"
               :show-close="false">
      <div class="register-body">
        <el-form ref="register" :model="register" :rules="rules" @keyup.enter.native="confirmRegister"
                 @keyup.esc.native="cancelRegister">
          <el-form-item prop="username">
            <el-input v-model="register.username" placeholder="请输入用户名"
                      prefix-icon="el-icon-s-custom"></el-input>
          </el-form-item>

          <el-form-item prop="password">
            <el-input v-model="register.password" placeholder="请输入密码" show-password
                      prefix-icon="el-icon-lock"></el-input>
          </el-form-item>

          <el-form-item prop="surePassword">
            <el-input v-model="register.surePassword" placeholder="请再次输入密码" show-password
                      prefix-icon="el-icon-lock"></el-input>
          </el-form-item>

          <el-form-item prop="inviteCode">
            <el-input class="custom-input-prefix-icon" v-model="register.inviteCode" placeholder="请输入邀请码"
                      prefix-icon="iconfont icon-liwu"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button class="light-btn register-cancel" @click="cancelRegister">取消</el-button>
    <el-button class="dark-btn register-confirm" @click="confirmRegister" v-loading.fullscreen.lock="loginLoading">注册</el-button>
  </span>
    </el-dialog>


  </div>
</template>

<script>
import {userLogin, userRegister} from "@/apis/user";
import util from "@/util/util";

export default {
  name: "CreLogin",
  data() {

    // 确认密码校验
    const checkSurePwd = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('确认密码不能为空'));
      } else if (value !== this.register.password) {
        callback(new Error('两次输入的密码不一致'));
      } else {
        callback();
      }
    };

    return {
      login: {
        username: '',
        password: '',
      },
      registerVisible: false,
      register: {
        username: '',
        password: '',
        surePassword: '',
        inviteCode: ''
      },
      // 登录完成的loading
      loginLoading: false,
      // 校验规则
      rules: {
        username: [
          {required: true, message: '用户名不能为空', trigger: 'blur'},
          {min: 6, message: '用户名不能小于6位', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '密码不能为空', trigger: 'blur'},
          {min: 6, message: '密码不能小于6位', trigger: 'blur'}
        ],
        surePassword: [
          {validator: checkSurePwd, trigger: 'blur'}
        ],
        inviteCode: [
          {required: true, message: '邀请码不能为空', trigger: 'blur'}
        ]
      }
    }
  },

  components: {},

  methods: {
    confirmLogin() {
      this.$refs['login'].validate((valid) => {
        if (valid) {
          // 打开loading
          this.loginLoading = true;
          userLogin({
            username: this.login.username,
            password: this.login.password
          }).then(res => {
            // 关闭loading
            this.loginLoading = false;
            if (res.data.code === 200) {
              // console.log(res.data);
              this.$store.commit('setUser', res.data.data);
              localStorage.token = res.data.data.tokenValue;
              if (res.data.data.roleLevel !== 3) {
                this.$router.push('/home/company');
              } else {
                this.$router.push('/home/tools');
              }
            }
          })
        }
      })
    },

    cancelRegister() {
      this.registerVisible = false;
      this.$refs['register'].resetFields();
    },

    confirmRegister() {
      this.$refs['register'].validate((valid) => {
        if (valid) {
          // 打开loading
          this.loginLoading = true;
          this.registerVisible = false;
          userRegister({
            username: this.register.username,
            password: this.register.password,
            surePassword: this.register.surePassword,
            inviteCode: this.register.inviteCode
          }).then(res => {
            // 关闭loading
            this.loginLoading = false;
            if (res.data.code === 200) {
              util.notifySuccess(res.data.data);
            }
          })
          this.$refs['register'].resetFields();
          this.$refs.register.clearValidate();
        }
      })
    },

    // 边输入边校验
    validateByInput(formRef, fieldProp) {
      this.$refs[formRef].validateField(fieldProp);
    },

    openRegister() {
      this.registerVisible = true;
      this.$refs.login.clearValidate();
    },
  },
}
</script>

<style lang="less" scoped>
@import "../assets/styles/main";
@import "../assets/fonts/iconfont.css";

.login-container {
  background-color: @back;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.login-backPic {
  width: 600px;

  img {
    width: 600px;
  }
}

.login-box {
  width: 600px;

  div {
    width: 400px;
    height: 320px;
    background-color: #fff;
    border-radius: 15px;
    padding: 20px;
    box-sizing: border-box;
  }
}

.login-box-div{
  box-shadow: 0 0 20px #d9d9d9;
}

/deep/ .login-form {
  margin-top: 40px;
}

.el-form /deep/ .el-form-item {
  padding: 0;
  margin: 15px auto 30px;
  width: 300px;
  height: 40px;
}

.el-form-item /deep/ .form-input {
  padding: 0;
  width: 300px;
  height: 40px;
}

.btns /deep/ .el-form-item__content {
  display: flex;
  width: 300px;
  justify-content: space-between;
  margin: 30px auto 0;
}

/deep/ .el-dialog {
  border-radius: 10px;
}


.beian:link, .beian:visited{
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: #8e9094;
  font-size: 10px;
  text-decoration: none;
}

</style>