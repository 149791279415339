import axios from "axios";
import router from "@/router";
import util from "@/util/util";

const http = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 10000
})

http.interceptors.request.use(config => {
    if (localStorage.token) {
        config.headers['token'] = localStorage.token;
    }
    return config;
})

http.interceptors.response.use(res => {
    switch (res.data.code) {
        case 200:
            return res;
        case 400:
            localStorage.removeItem('token');
            router.push('/');
            return res;
        default:
            util.notifyFail(res.data.data);
            return res;
    }
})

export default http;