import ElementUI from "element-ui";

const util = {

    // 将target数组中的元素合并到src数组中
    mergeArr(srcArr, targetArr) {
        targetArr.forEach(item => srcArr.push(item));
    },

    // 封装elementUI提示
    notifySuccess(message) {
        ElementUI.Notification({
            type: 'success',
            title: '成功',
            message: message,
            duration: 2000,
            position: 'bottom-right'
        })
    },

    notifyFail(message) {
        ElementUI.Notification({
            type: "error",
            title: '失败',
            message: message,
            position: 'bottom-right'
        });
    },

    // 清除对象中的属性值
    clearObj(obj) {
        Object.keys(obj).forEach(key => obj[key] = '');
    },

    // 根据用户累计登录天数计算账号等级
    getAccountLevel(days) {
        if (0 <= days && days < 10) {
            return 1;
        } else if (10 <= days && days < 30) {
            return 2;
        } else if (30 <= days && days < 60) {
            return 3;
        } else if (60 <= days && days < 200) {
            return 4;
        } else {
            return 5;
        }
    },

    // 驼峰转下划线
    toLine(name) {
        return name.replace(/([A-Z])/g, "_$1").toLowerCase();
    }
}

export default util;