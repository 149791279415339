import http from "@/apis/http";

export let userLogin = params => http.post('/user/login', params);
export let userRegister = params => http.post('/user/register', params);
export let userRoleLevel = () => http.get('/user/role_level');
export let userInfo = () => http.get('/user/info');
export let userChangePwd = params => http.post('/user/change_pwd', params);
export let userList = params => http.post('/user/list', params);
export let changeUserRole = params => http.post('/user/change_role', params);
export let userFullInfo = userId => http.get(`/user/full_info?userId=${userId}`);
export let changeIsDel = params => http.post('/user/change_del', params);