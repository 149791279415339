import Vue from 'vue'
import VueRouter from 'vue-router'
import CreLogin from "@/views/CreLogin";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'creLogin',
    component: CreLogin
  },
  {
    path: '/home',
    name: 'homeMainPage',
    component: () => import('@/views/home/MainPage'),
    children: [
      {
        path: 'company',
        name: 'company',
        component: () => import('@/views/home/CompanyRecommend')
      },
      {
        path: 'tools',
        name: 'tools',
        component: () => import('@/views/home/UsualTools')
      },
      {
        path: 'myRecords',
        name: 'myManage',
        component: () => import('@/views/home/MyRecords')
      },
      {
        path: 'accountMgr',
        name: 'accountMgr',
        component: () => import('@/views/home/AccountMgr')
      },
    ]
  },
  {
    path: '*',
    name: 'error',
    component: () => import('@/views/Error404')
  }
]

const router = new VueRouter({
  routes
})


// 路由拦截
const whiteList = ['/'];
router.beforeEach((to, from, next) => {
  // 白名单全部放行
  if (whiteList.includes(to.path)) {
    next();
  }
  // 否则检查是否有token
  if (!localStorage.token) {
    next('/');
  } else {
    next();
  }
})

export default router
